'use client';

import { Checkbox, MultiSelect, PasswordInput, Select, TextInput } from "@mantine/core";
import { useMergedRef } from "@mantine/hooks";
import clsx from "clsx";
import { memo, ReactNode } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { useIMask } from "react-imask";

import { FormItem } from "@/app/types/shared";

import PasswordFieldWithStrength from "../password-field-with-strength";
import styles from './index.module.scss';

type BaseFormItemProps<T extends FieldValues> = {
    control: Control<T>
    formItem: FormItem<Path<T>>
};

export default memo(
    function BaseFormItem<T extends FieldValues> (props: BaseFormItemProps<T>) {
        const { control, formItem } = props;
        const { field, fieldState } = useController({
            control: control as unknown as Control<FieldValues>,
            rules: formItem.rules,
            name: formItem.name,
            defaultValue: formItem.defaultValue ?? undefined as any
        });
        const { ref: maskRef } = useIMask(formItem.mask || {},
            {
                onAccept: value => {
                    value !== field.value && field.onChange(value);
                }
            }
        );

        console.log(fieldState, field.value);

        const mergedRef = useMergedRef(
            field.ref,
            maskRef
        );

        const sharedProps = {
            size: formItem.size,
            className: formItem.className,
            ref: mergedRef,
            error: fieldState.error?.message
        };

        const sharedTextInputsProps = {
            variant: formItem?.variant || 'filled',
            label: formItem?.label || undefined,
            placeholder: formItem?.placeholder || undefined,
            required: formItem.required
        };

        let formItemElement: ReactNode = '';

        if (formItem.type === 'checkbox') {
            formItemElement = (
                <Checkbox
                    {...formItem}
                    {...field}
                    {...sharedProps}
                    {...sharedTextInputsProps}
                    size={formItem.size}
                    onChange={(event => field.onChange(event.target.checked))}
                    checked={field.value}
                />
            );
        }

        if (formItem.type === 'checkboxGroup') {
            const children = formItem.checkboxes.map((checkbox, idx) => (
                <Checkbox key={checkbox.value?.toString() ?? idx} {...checkbox} />
            ));

            formItemElement = (
                <Checkbox.Group
                    {...formItem}
                    {...field}
                    {...sharedProps}
                >
                    {children}
                </Checkbox.Group>
            );
        }

        if (formItem.type === 'textInput') {
            formItemElement = (
                <TextInput
                    {...formItem}
                    {...field}
                    {...sharedProps}
                    {...sharedTextInputsProps}
                    withAsterisk={formItem.withAsterisk}
                />
            );
        }


        if (formItem.type === 'passwordInput') {
            if (formItem.showStrengthMeter) {
                formItemElement = (
                    <PasswordFieldWithStrength
                        {...field}
                        {...sharedProps}
                        {...sharedTextInputsProps}
                        withAsterisk={formItem.withAsterisk}
                    />
                );
            } else {
                formItemElement = (
                    <PasswordInput
                        {...field}
                        {...sharedProps}
                        {...sharedTextInputsProps}
                        withAsterisk={formItem.withAsterisk}
                    />
                );
            }
        }

        if (formItem.type === 'select') {
            formItemElement = (
                <Select
                    {...formItem}
                    {...field}
                    {...sharedProps}
                    {...sharedTextInputsProps}
                    comboboxProps={{
                        ...(formItem.comboboxProps || {}),
                        withinPortal: false
                    }}
                />
            );
        }

        if (formItem.type === 'multiSelect') {
            formItemElement = (
                <MultiSelect
                    {...formItem}
                    {...field}
                    {...sharedProps}
                    {...sharedTextInputsProps}
                    comboboxProps={{
                        ...(formItem.comboboxProps || {}),
                        withinPortal: false
                    }}
                />
            );
        }


        return (
            <div className={clsx([styles.baseFormItem, formItem.type])}>
                {formItemElement}
            </div>
        );
    }
);